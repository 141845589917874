<template>
  <v-row justify="center">
    <v-col cols="10" md="10" class="text-center">
      <h3 class="mb-4">{{ user.name ? user.name : "N/A" }}</h3>
      <p>Basic info, like name and others</p>
    </v-col>

    <v-col cols="10" md="10">
      <e-info-card title="Details" sub-title="Details of user">
        <template slot="basic-info-slot">
          <v-expansion-panels class="my-4">
            <v-expansion-panel class="" elevation="1">
              <v-expansion-panel-header class="border-b-2 border-gray-600">
                <div class="flex justify-between sm:items-center items-start">
                  <div
                    class="md:flex block justify-between sm:items-center items-start flex-1"
                  >
                    <div class="flex-grow" style="flex-basis: 136px">
                      <p class="uppercase text-xs m-0 mb-3 mr-4">Address</p>
                    </div>
                    <div
                      class="flex-grow text-left mr-2"
                      style="flex-basis: 136px"
                    >
                      <p class="m-0">
                        {{ user.address ? user.address : "N/A" }}
                      </p>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-header>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels class="my-4">
            <v-expansion-panel class="" elevation="1">
              <v-expansion-panel-header class="border-b-2 border-gray-600">
                <div class="flex justify-between sm:items-center items-start">
                  <div
                    class="md:flex block justify-between sm:items-center items-start flex-1"
                  >
                    <div class="flex-grow" style="flex-basis: 136px">
                      <p class="uppercase text-xs m-0 mb-3 mr-4">
                        Date of Birth
                      </p>
                    </div>
                    <div
                      class="flex-grow text-left mr-2"
                      style="flex-basis: 136px"
                    >
                      <p class="m-0">{{ user.dob ? user.dob : "N/A" }}</p>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-header>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels class="my-4">
            <v-expansion-panel class="" elevation="1">
              <v-expansion-panel-header class="border-b-2 border-gray-600">
                <div class="flex justify-between sm:items-center items-start">
                  <div
                    class="md:flex block justify-between sm:items-center items-start flex-1"
                  >
                    <div class="flex-grow" style="flex-basis: 136px">
                      <p class="uppercase text-xs m-0 mb-3 mr-4">Role</p>
                    </div>
                    <div
                      class="flex-grow text-left mr-2"
                      style="flex-basis: 136px"
                    >
                      <p class="m-0">
                        {{ user && user.role.name ? user.role.name : "N/A" }}
                      </p>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-header>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </e-info-card>
    </v-col>
    <v-col cols="10" md="10">
      <e-info-card title="Contact info">
        <template slot="basic-info-slot">
          <v-expansion-panels class="my-4">
            <v-expansion-panel class="" elevation="1">
              <v-expansion-panel-header class="border-b-2 border-gray-600">
                <div class="flex justify-between sm:items-center items-start">
                  <div
                    class="md:flex block justify-between sm:items-center items-start flex-1"
                  >
                    <div class="flex-grow" style="flex-basis: 136px">
                      <p class="uppercase text-xs m-0 mb-3 mr-4">Email</p>
                    </div>
                    <div
                      class="flex-grow text-left mr-2"
                      style="flex-basis: 136px"
                    >
                      <p class="m-0">{{ user.email ? user.email : "" }}</p>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-header>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </e-info-card>
      <v-btn
        color="info"
        class="mt-3 white--text"
        @click="$router.push('/app/users')"
      >
        <v-icon left dark> mdi-arrow-left </v-icon>
        Go Back
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import EInfoCard from "@/components/card/BasicInfoCard";
import { GET_USER_DETAIL } from "../../../constants/graphql";
export default {
  components: {
    EInfoCard,
  },
  data() {
    return {
      user: "",
      userId: parseInt(this.$route.params.id),
    };
  },

  apollo: {
    user: {
      query: GET_USER_DETAIL,
      variables() {
        return {
          userId: this.userId,
        };
      },
      update: (result) => result.getSingleUser,
      error(error) {
        handleError(error);
      },
    },
  },
};
</script>
